// import { Turbo } from '@hotwired/turbo-rails';
import * as ActiveStorage from '@rails/activestorage';
import Rails from '@rails/ujs';

import './jquery';

import 'owl.carousel';
import 'chart.js';

ActiveStorage.start();
Rails.start();
